import React from "react";
import ContactHeader from "~components/ContactHeader";
import Logo from "~components/svg/Logo";

const Footer = () => {
  return (
    <footer className="w-screen relative h-auto bg-hard-red text-black">
      <div className="w-full relative pt-v2 pb-v2 xs:pt-v8 xs:pb-v8 flex flex-col justify-between h-full">
        <ContactHeader color="onyx" withDetails />

        <div className="grid">
          <img
            src="/uploads/footer_monogram.gif"
            className="footer__monogram animation-appear-slow animation-delay-5 grid-end-6 relative"
            alt="monogram.gif"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
