/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import PropTypes from "prop-types";

const Logo = ({ className, color }) => {
  switch (color) {
    case `onyx`:
      color = `#191919`;
      break;

    case `orange`:
      color = `#EA5046`;
      break;

    case `hard-red`:
      color = `#F03C0A`;
      break;

    case `black`:
      color = `#000`;
      break;

    default:
      break;
  }

  //

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 646 211.34"
    >
      <defs>
        <style></style>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g className="line-1">
          <path
            fill={color}
            d="M19.82,17A37.22,37.22,0,0,0,14.2,28a42.46,42.46,0,0,0-1.83,12.57A43.59,43.59,0,0,0,14.2,53.24,35.67,35.67,0,0,0,19.82,64.1a30,30,0,0,0,9.73,8.68,22.84,22.84,0,0,0,22.91,0A30.12,30.12,0,0,0,62.2,64.1a33.9,33.9,0,0,0,5.49-10.86,41.11,41.11,0,0,0,2-12.68,40,40,0,0,0-2-12.57A35.32,35.32,0,0,0,62.2,17a30.7,30.7,0,0,0-9.74-8.57A23.16,23.16,0,0,0,41,5.37,23.8,23.8,0,0,0,29.44,8.45,31,31,0,0,0,19.82,17Zm50.05-5.14A39.46,39.46,0,0,1,78.8,25a39.22,39.22,0,0,1,0,31.08,39.46,39.46,0,0,1-8.93,13.14A41.27,41.27,0,0,1,56.47,78,40,40,0,0,1,41,81.12,40.8,40.8,0,0,1,25.43,78a41.67,41.67,0,0,1-13.29-8.79,41,41,0,0,1-9-13.14A40.62,40.62,0,0,1,3.09,25a41,41,0,0,1,9.05-13.14,41.7,41.7,0,0,1,13.29-8.8A41,41,0,0,1,41,0,40.12,40.12,0,0,1,56.47,3.08,41.3,41.3,0,0,1,69.87,11.88Z"
          />
          <path
            fill={color}
            d="M121.14,6.17h-8.36V38.05h8.36q10.08,0,15.69-4.23t5.73-12q0-7.55-5.61-11.54T121.14,6.17ZM112.78,75h11.8v5H90V75h11.79V6.17H90v-5h31.27q15.79,0,24.28,5.26t8.59,15a17.05,17.05,0,0,1-5.84,13q-5.73,5.25-16,7.31L167.07,80h-14.2L120.34,42.73h-7.56Z"
          />
          <path
            fill={color}
            d="M242.8,38.5q0-15.3-10.54-23.76T202.94,6.17h-6.19V75h6.42q18.21,0,28.86-9.83T242.8,38.5ZM174,1.14h29.89q23.15,0,37.23,10.17t14.09,27.08q0,18.28-14.2,29.93T205,80H174V75h11.8V6.17H174Z"
          />
          <polygon
            fill={color}
            points="264.34 6.17 264.34 1.14 298.93 1.14 298.93 6.17 287.13 6.17 287.13 74.95 298.93 74.95 298.93 79.98 264.34 79.98 264.34 74.95 276.14 74.95 276.14 6.17 264.34 6.17"
          />
          <polygon
            fill={color}
            points="337.89 74.95 337.89 79.98 308.34 79.98 308.34 74.95 320.13 74.95 320.13 14.51 308.45 1.14 322.43 1.14 374.89 62.15 374.89 6.17 363.09 6.17 363.09 1.14 392.64 1.14 392.64 6.17 380.84 6.17 380.84 83.75 326.09 21.25 326.09 74.95 337.89 74.95"
          />
          <path
            fill={color}
            d="M433.12,16.45,421.44,45.13h23.13Zm-2.75-6.62-3.44-8.69h11.23L468.4,75h9.51v5h-35.4V75H456.6l-10-24.79H419.38L409.3,75h14.08v5H393.83V75h9.51Z"
          />
          <path
            fill={color}
            d="M518.73,6.17h-8.36V38.05h8.36q10.08,0,15.69-4.23t5.73-12q0-7.55-5.61-11.54T518.73,6.17ZM510.37,75h11.8v5h-34.6V75h11.8V6.17h-11.8v-5h31.27q15.81,0,24.29,5.26t8.59,15a17.05,17.05,0,0,1-5.84,13q-5.73,5.25-16,7.31L564.66,80h-14.2L517.93,42.73h-7.56Z"
          />
          <polygon
            fill={color}
            points="623.55 74.95 623.55 79.98 586.67 79.98 586.67 74.95 599.61 74.95 599.61 41.93 573.72 6.17 564.22 6.17 564.22 1.14 600.64 1.14 600.64 6.17 586.55 6.17 607.86 37.13 629.96 6.17 615.88 6.17 615.88 1.14 646 1.14 646 6.17 636.49 6.17 610.61 41.93 610.61 74.95 623.55 74.95"
          />
        </g>
        <g className="line-2">
          <polygon
            fill={color}
            points="27.17 143.59 44.01 143.59 53.05 131.37 53.05 160.96 44.01 148.62 27.17 148.62 27.17 181.41 55.92 181.41 72.18 162.1 67.37 186.44 4.38 186.44 4.38 181.41 16.17 181.41 16.17 112.63 4.38 112.63 4.38 107.6 59.24 107.6 65.43 104.29 65.43 128.51 53.63 112.63 27.17 112.63 27.17 143.59"
          />
          <path
            fill={color}
            d="M101.65,123.49a37.38,37.38,0,0,0-5.61,11A42.46,42.46,0,0,0,94.21,147,43.54,43.54,0,0,0,96,159.7a35.87,35.87,0,0,0,5.61,10.86,30.12,30.12,0,0,0,9.74,8.68,22.84,22.84,0,0,0,22.91,0,30.12,30.12,0,0,0,9.74-8.68,33.9,33.9,0,0,0,5.49-10.86A41.06,41.06,0,0,0,151.48,147a40,40,0,0,0-1.95-12.57,35.26,35.26,0,0,0-5.49-11,30.46,30.46,0,0,0-9.74-8.57,23.07,23.07,0,0,0-11.46-3.09,23.66,23.66,0,0,0-11.56,3.09A30.86,30.86,0,0,0,101.65,123.49Zm50.06-5.15a39.46,39.46,0,0,1,8.93,13.14,39.74,39.74,0,0,1-7.56,42.85,40,40,0,0,1-26.23,13l55.33,20-21.42,4-53.15-26.73a41.73,41.73,0,0,1-18.78-15,39,39,0,0,1-7-22.62,40.05,40.05,0,0,1,3.09-15.54,41,41,0,0,1,9-13.14,41.56,41.56,0,0,1,13.29-8.79,40.79,40.79,0,0,1,15.57-3.09,40,40,0,0,1,15.47,3.09A41.38,41.38,0,0,1,151.71,118.34Z"
          />
          <path
            fill={color}
            d="M215,180.38q10.65,0,16.49-6.4t6-18.28V112.63h-11.8v-5h29.55v5h-11.8v44.79q0,13.37-8.59,21.82T213,187.58q-13.39,0-22.56-8.11t-9.17-21.71V112.63H169.49v-5h34.59v5h-11.8v43q0,11.78,6,18.28T215,180.38Z"
          />
          <polygon
            fill={color}
            points="264.36 112.63 264.36 107.6 298.95 107.6 298.95 112.63 287.15 112.63 287.15 181.41 298.95 181.41 298.95 186.44 264.36 186.44 264.36 181.41 276.15 181.41 276.15 112.63 264.36 112.63"
          />
          <polygon
            fill={color}
            points="341.39 112.63 321.8 112.63 310.01 129.66 310.01 104.29 316.19 107.6 377.59 107.6 383.77 104.29 383.77 129.66 371.97 112.63 352.39 112.63 352.39 181.41 365.33 181.41 365.33 186.44 328.45 186.44 328.45 181.41 341.39 181.41 341.39 112.63"
          />
          <polygon
            fill={color}
            points="453.37 181.41 453.37 186.44 416.49 186.44 416.49 181.41 429.43 181.41 429.43 148.39 403.55 112.63 394.04 112.63 394.04 107.6 430.46 107.6 430.46 112.63 416.37 112.63 437.68 143.59 459.79 112.63 445.7 112.63 445.7 107.6 475.82 107.6 475.82 112.63 466.31 112.63 440.43 148.39 440.43 181.41 453.37 181.41"
          />
          <path
            fill={color}
            d="M530.85,127.17h3.54l-1.67-17.52-7.35,20.91-7.43-20.91-1.6,17.52h3.54v2.07h-8.54v-2.07h2.78l1.6-17.32h-2.85v-2.14h9l4.58,14,4.65-14h8.74v2.14H537l1.59,17.32h2.78v2.07H530.85ZM487.6,106.81l1.67.9H507l1.67-.9v8l-3.75-5h-4.65v17.38h4.3v2.07H491.77v-2.07H496V109.79h-4.58l-3.82,5Z"
          />
        </g>
      </g>
    </svg>
  );
};

Logo.defaultProps = {
  className: ``
};

Logo.propTypes = {
  className: PropTypes.string
};

export default Logo;
