import React from "react";

const ContactHeader = ({ color }) => {
  return (
    <div className={`grid z-10 text-${color}`}>
      <div className="animation-appear-right animation-delay-3 grid-end-3 xs:grid-end-6 b2">
        The Value of Patience
      </div>

      <div className="animation-appear-right animation-delay-3 grid-start-10 grid-end-3 xs:grid-end-6 xs:grid-start-7 contact-header__info">
        <p className="b2 hover-underline">
          <a
            href="https://www.google.com/maps?client=firefox-b-d&q=Work+Club,+Barrack+Place+151+Clarence+St,+NSW+2000&um=1&ie=UTF-8&sa=X&ved=2ahUKEwi00-2No8ftAhXIxzgGHaWJBFMQ_AUoAXoECAYQAw"
            target="_blank"
            rel="noopener noreferrer"
          >
            Work Club, Barrack Place,
            <br /> 151 Clarence St, NSW 2000
          </a>
        </p>
        <p className="b2 hover-underline">
          <a
            href="https://www.google.com/maps/place/Level+6%2F437+St+Kilda+Rd,+Melbourne+VIC+3004,+Australia/@-37.8390491,144.9755597,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad6681c262ab673:0x2d32e477466ea492!8m2!3d-37.8390534!4d144.9777484"
            rel="noopener noreferrer"
          >
            Level 6, 437 St Kilda Road
            <br /> VIC 3004
          </a>
        </p>
        <p className="b2 hover-underline">
          <a href="mailto:enquiries@ordinaryequity.com.au">
            enquiries@ordinaryequity.com.au
          </a>
        </p>
      </div>
    </div>
  );
};

export default ContactHeader;
